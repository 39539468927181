<template>
<div :key="`key_${key}`">
    <div class="kt-portlet kt-portlet--height-fluid recent-messages">
        <div class="kt-portlet__head kt-portlet__head--noborder">
            <div class="kt-portlet__head-label">
                <div class="kt-input-icon kt-input-icon--left">
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Filter..."
                        debounce="500"
                        @input="event => changeFilter(event.target.value)"
                    >
                    <span class="kt-input-icon__icon kt-input-icon__icon--left">
                        <span><i class="la la-search" /></span>
                    </span>
                </div>
            </div>
            <div class="kt-portlet__head-toolbar">
                <button
                    v-if="toggleShowDeleted !== null"
                    class="btn btn-bold"
                    :class="{ 'btn-danger': showDeleted, 'btn-label-danger': !showDeleted }"
                    @click.prevent.stop="toggleShowDeleted"
                >
                    {{ showDeleted ? 'Hide Deleted' : 'Deleted' }}
                </button>
            </div>
        </div>
        <div class="kt-portlet__body">
            <div
                class="kt-scroll"
                style="height: 55vh; overflow-y: auto; overflow-x: hidden;"
            >
                <div class="kt-widget5">
                    <div v-if="messages.length">
                        <div
                            v-for="(message, idx) in messages"
                            :key="`message_${message.communicationMessageId}_${idx}`"
                            class="kt-widget5__item"
                        >
                            <div class="kt-widget5__content">
                                <div class="kt-widget5__pic">
                                    <div
                                        v-if="!message.deleted"
                                        class="kt-badge kt-badge--lg"
                                        :class="{
                                            'kt-badge--success': !message.isSchoolAnnouncement,
                                            'kt-badge--warning': message.isSchoolAnnouncement,
                                        }"
                                    >
                                        <span v-if="message.isSchoolAnnouncement">
                                            <i class="fa fa-bullhorn" />
                                        </span>
                                        <span v-else>
                                            {{ message.author.substring(0,2) }}
                                        </span>
                                    </div>
                                    <div
                                        v-else
                                        class="kt-badge kt-badge--lg kt-badge--danger"
                                    >
                                        <i class="fa fa-trash-alt" />
                                    </div>
                                </div>
                                <router-link
                                    :to="{ name: 'ViewMessage', params: { messageId: message.messageId, studentEnrollmentId: studentEnrollmentId || null } }"
                                    class="kt-widget5__section"
                                >
                                    <span class="kt-widget5__title message-subject">
                                        {{ message.messageSubject }}
                                    </span>
                                    <p
                                        class="kt-widget5__desc message-body"
                                    >
                                        {{ message.messageBody }}
                                    </p>
                                    <div class="kt-widget5__info">
                                        <span>{{ 'From: ' }}</span>
                                        <span class="kt-font-info">{{ message.author }}</span>
                                        <span>{{ 'Last Active: ' }}</span>
                                        <span class="kt-font-info">{{ message.lastMessageAt }}</span>
                                    </div>
                                </router-link>
                            </div>
                            <div class="kt-widget5__content">
                                <div v-if="!message.messageRead" class="kt-widget5__stats">
                                    <span class="kt-widget5__number kt-font-danger kt-font-md">New Message</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        No messages found
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { mapState } from 'vuex';
import UserAvatar from '../UserAvatar.vue';

export default {
    name: 'MessageInbox',
    components: {
        UserAvatar,
    },
    props: {
        messages: {
            type: Array,
            required: true,
        },
        changeFilter: {
            type: Function,
            required: true,
        },
        showDeleted: {
            type: Boolean,
            required: true,
        },
        toggleShowDeleted: {
            type: Function,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            key: 0,
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
        }),
        studentEnrollmentId() {
            return this.$route.params.studentEnrollmentId;
        },
    },
    watch: {

    },
};
</script>

<style scoped>
.recent-messages {
    min-height: 70vh !important;
}
.message-subject {
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    line-height: 1.2em !important;
    height: 1.2em !important;
}
.message-body {
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    line-height: 1.5em !important;
    height: 2.3em !important;
}
</style>
