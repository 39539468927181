var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("b-modal", {
    ref: "auth-method-modal",
    staticClass: "auth-method-modal",
    attrs: {
      id: "auth-method-modal",
      size: "lg",
      variant: "primary",
      centered: "",
    },
    scopedSlots: _vm._u([
      {
        key: "modal-header",
        fn: function ({ close }) {
          return [
            _c("h5", { staticClass: "modal-title" }, [
              _vm._v(" Add Two Factor Method "),
            ]),
            _c("button", {
              staticClass: "close",
              attrs: {
                type: "button",
                "data-dismiss": "modal",
                "aria-label": "Close",
              },
              on: { click: close },
            }),
          ]
        },
      },
      {
        key: "default",
        fn: function () {
          return [
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "kt-form kt-form--label-right" }, [
                _c("div", { staticClass: "kt-form__body" }, [
                  _c("div", { staticClass: "kt-section kt-section--first" }, [
                    _c("div", { staticClass: "kt-section__body" }, [
                      _c("div", { staticClass: "form-group validated row" }, [
                        _c(
                          "label",
                          { staticClass: "col-xl-3 col-lg-3 col-form-label" },
                          [_vm._v("Authentication Method")]
                        ),
                        _c("div", { staticClass: "col-lg-9 col-xl-6" }, [
                          _c(
                            "div",
                            { staticClass: "kt-radio-inline" },
                            _vm._l(_vm.twoFactorTypes, function (t) {
                              return _c(
                                "label",
                                { key: t, staticClass: "kt-radio" },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.twoFactorType,
                                        expression: "twoFactorType",
                                      },
                                    ],
                                    attrs: { type: "radio", name: "radio4" },
                                    domProps: {
                                      value: t,
                                      checked: _vm._q(_vm.twoFactorType, t),
                                    },
                                    on: {
                                      change: function ($event) {
                                        _vm.twoFactorType = t
                                      },
                                    },
                                  }),
                                  _vm._v(" " + _vm._s(t) + " "),
                                  _c("span"),
                                ]
                              )
                            }),
                            0
                          ),
                        ]),
                      ]),
                      _vm.twoFactorType == "Email"
                        ? _c(
                            "div",
                            { staticClass: "form-group validated row" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "col-xl-3 col-lg-3 col-form-label",
                                },
                                [_vm._v("Via Email")]
                              ),
                              _c("div", { staticClass: "col-lg-9 col-xl-6" }, [
                                _c("div", { staticClass: "form-group" }, [
                                  _c("div", { staticClass: "input-group" }, [
                                    _c(
                                      "div",
                                      { staticClass: "input-group-prepend" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "input-group-text" },
                                          [_vm._v("@")]
                                        ),
                                      ]
                                    ),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.emailAddress,
                                          expression: "emailAddress",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      class: _vm.isEmailAddressValid
                                        ? ""
                                        : "is-invalid",
                                      attrs: {
                                        type: "text",
                                        placeholder: "Email Address",
                                      },
                                      domProps: { value: _vm.emailAddress },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.emailAddress = $event.target.value
                                        },
                                      },
                                    }),
                                  ]),
                                  !_vm.isEmailAddressValid
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-text invalid-feedback",
                                        },
                                        [
                                          _vm._v(
                                            " Please enter valid email address that is not accessible from this device. "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.twoFactorType == "SMS"
                        ? _c(
                            "div",
                            { staticClass: "form-group validated row" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "col-xl-3 col-lg-3 col-form-label",
                                },
                                [_vm._v("Via Text Message")]
                              ),
                              _c("div", { staticClass: "col-lg-9 col-xl-6" }, [
                                _c("div", { staticClass: "form-group" }, [
                                  _c("div", { staticClass: "input-group" }, [
                                    _c(
                                      "div",
                                      { staticClass: "input-group-prepend" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "input-group-text" },
                                          [
                                            _c("i", {
                                              staticClass: "la la-phone",
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.phoneNumber,
                                          expression: "phoneNumber",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      class: _vm.isPhoneNumberValid
                                        ? ""
                                        : "is-invalid",
                                      attrs: {
                                        type: "text",
                                        placeholder: "Mobile Number",
                                      },
                                      domProps: { value: _vm.phoneNumber },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.phoneNumber = $event.target.value
                                        },
                                      },
                                    }),
                                  ]),
                                  !_vm.isPhoneNumberValid
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-text invalid-feedback",
                                        },
                                        [
                                          _vm._v(
                                            " Please enter valid US 10 digit phone number eg. 2125557890 "
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "span",
                                        { staticClass: "form-text text-muted" },
                                        [
                                          _vm._v(
                                            " Text messages / SMS may carry additional charges from your service provider. "
                                          ),
                                        ]
                                      ),
                                ]),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "modal-footer",
        fn: function () {
          return [
            _c("div", { staticClass: "row w-100" }, [
              _c(
                "button",
                {
                  staticClass:
                    "btn btn-label-brand btn-bold kt-margin-t-5 kt-margin-b-5 pull-right",
                  class: {
                    "kt-spinner kt-spinner--sm kt-spinner--light": _vm.saving,
                  },
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.saveTwoFactorSettings.apply(null, arguments)
                    },
                  },
                },
                [
                  !_vm.saving
                    ? _c("i", { staticClass: "fa fa-save" })
                    : _vm._e(),
                  _vm._v(" Add Auth Method "),
                ]
              ),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }