<template>
<b-modal
    id="auth-method-modal"
    ref="auth-method-modal"
    class="auth-method-modal"
    size="lg"
    variant="primary"
    centered
>
    <template #modal-header="{ close }">
        <h5 class="modal-title">
            Add Two Factor Method
        </h5>

        <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="close"
        />
    </template>
    <template #default>
        <div class="modal-body">
            <div class="kt-form kt-form--label-right">
                <div class="kt-form__body">
                    <div class="kt-section kt-section--first">
                        <div class="kt-section__body">
                            <div class="form-group validated row">
                                <label class="col-xl-3 col-lg-3 col-form-label">Authentication Method</label>
                                <div class="col-lg-9 col-xl-6">
                                    <div class="kt-radio-inline">
                                        <label
                                            v-for="t in twoFactorTypes"
                                            :key="t"
                                            class="kt-radio"
                                        >
                                            <input
                                                v-model="twoFactorType"
                                                :value="t"
                                                type="radio"
                                                name="radio4"
                                            > {{ t }}
                                            <span />
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="twoFactorType == 'Email'"
                                class="form-group validated row"
                            >
                                <label class="col-xl-3 col-lg-3 col-form-label">Via Email</label>
                                <div class="col-lg-9 col-xl-6">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">@</span>
                                            </div>
                                            <input
                                                v-model="emailAddress"
                                                type="text"
                                                class="form-control"
                                                placeholder="Email Address"
                                                :class="isEmailAddressValid ? '' : 'is-invalid'"
                                            >
                                        </div>
                                        <div
                                            v-if="!isEmailAddressValid"
                                            class="form-text invalid-feedback"
                                        >
                                            Please enter valid email address that is not accessible from this device.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="twoFactorType == 'SMS'"
                                class="form-group validated row"
                            >
                                <label class="col-xl-3 col-lg-3 col-form-label">Via Text Message</label>
                                <div class="col-lg-9 col-xl-6">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">
                                                    <i class="la la-phone" />
                                                </span>
                                            </div>
                                            <input
                                                v-model="phoneNumber"
                                                type="text"
                                                class="form-control"
                                                placeholder="Mobile Number"
                                                :class="isPhoneNumberValid ? '' : 'is-invalid'"
                                            >
                                        </div>
                                        <div
                                            v-if="!isPhoneNumberValid"
                                            class="form-text invalid-feedback"
                                        >
                                            Please enter valid US 10 digit phone number eg. 2125557890
                                        </div>
                                        <span
                                            v-else
                                            class="form-text text-muted"
                                        > Text messages / SMS may carry additional charges from your service provider. </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </template>
    <template #modal-footer>
        <div class="row w-100">
            <button
                type="button"
                class="btn btn-label-brand btn-bold kt-margin-t-5 kt-margin-b-5 pull-right"
                :class="{ 'kt-spinner kt-spinner--sm kt-spinner--light': saving }"
                @click.stop.prevent="saveTwoFactorSettings"
            >
                <i
                    v-if="!saving"
                    class="fa fa-save"
                />
                Add Auth Method
            </button>
        </div>
    </template>
</b-modal>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Vue from 'vue';
import * as network from '../network';
import Types from '../store/Types';
// import twoFactorMixins from '../store/mixins/twoFactorMixins';

export default Vue.extend({
    name: 'TwoFactorAuthMethodModal',
    data() {
        return {
            twoFactorTypes: [
                'Email',
                'SMS',
            ],
            twoFactorType: 'Email',
            emailAddress: null,
            phoneNumber: null,
            saving: false,
            verify: false,
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            isSuperAdmin: (state) => state.user.isSuperAdmin,
            deviceType: (state) => state.deviceType,
        }),
        ...mapGetters({
            twoFactorEnabled: Types.getters.IS_TWO_FACTOR_AUTH_ENABLED,
            twoFactorRequired: Types.getters.IS_TWO_FACTOR_AUTH_REQUIRED,
        }),
        twoFactorAuthMethods() {
            return this.$_twoFactorMixins_authMethods;
        },
        isEmailAddressValid() {
            const emailAddress = (this.emailAddress || '').toLowerCase();
            if (!emailAddress) return false;
            const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return re.test(emailAddress);
        },
        isPhoneNumberValid() {
            const phoneNumber = this.phoneNumber || '';
            if (!phoneNumber) return false;
            const re = new RegExp(/^[0-9]{10}$/);
            return re.test(phoneNumber);
        },

    },
    mounted() {
        this.verify = false;
        this.populate();
    },
    methods: {
        showAddNewMethodModal() {
            this.$refs['two-factor-auth-method-modal'].open();
        },
        populate() {
            const v = this;
            if (!v.isSuperAdmin) return false;
            network.admin.getSuperAdminGoogleAccount({}, (err, res) => {
                if (err) return v.showError(err);
                if (res.googleAccount && res.googleAccount.email) {
                    v.linkedSuperGmail = true;
                }
            });
        },
        startChallenge() {
            this.verify = true;
        },
        saveTwoFactorSettings() {
            const v = this;

            const {
                isPhoneNumberValid, isEmailAddressValid,
                emailAddress, phoneNumber,
                showError, hide,
            } = v;

            if (this.twoFactorType == 'Email' && !isEmailAddressValid) return showError('Enter a valid email address');
            if (this.twoFactorType == 'SMS' && !isPhoneNumberValid) return showError('Enter a valid phone number');

            if (v.saving) return;
            v.saving = true;

            const twoFactorAuthMethod = {
                authMethod: v.twoFactorType,
                address: v.twoFactorType == 'Email' ? emailAddress : phoneNumber,
            };

            const save2FA = Types.actions.SAVE_TWO_FACTOR_AUTH_SETTINGS;
            v.$store.dispatch(save2FA, {
                twoFactorAuthMethod,
                callback: (err) => {
                    v.saving = false;
                    if (err) return v.showError(err);
                    v.$store.dispatch('refreshSession');
                    hide();
                },
            });
        },
        open() {
            this.$refs['auth-method-modal'].show();
        },
        hide() {
            this.$refs['auth-method-modal'].hide();
        },
    },
});

</script>

<style scoped>
.modal-body {
    max-height: 80vh;
    overflow: scroll;
}
</style>
