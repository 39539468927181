<template>
<div
    v-if="user"
    :key="key"
>
    <div class="kt-widget4 p-3">
        <div
            v-for="authMethod in twoFactorMixinsAuthMethods"
            :key="`${authMethod.twoFactorType}-${authMethod.twoFactorAddress}`"
            class="kt-widget4__item"
        >
            <div
                v-if="!authMethod.twoFactorValidated"
                class="kt-widget4__pic kt-widget4__pic--pic"
            >
                <a
                    href="#"
                    class="btn btn-sm btn-label-brand btn-bold text-nowrap"
                    @click.stop.prevent="startChallengeForMethod(authMethod)"
                >
                    <i class="la la-check" />
                    Verify
                </a>
            </div>
            <div
                v-if="!fullScreen || !authMethod.twoFactorValidated"
                class="kt-widget4__pic kt-widget4__pic--pic"
            >
                <a

                    href="#"
                    class="btn btn-sm btn-label-danger btn-bold text-nowrap"
                    @click.stop.prevent="remove(authMethod)"
                >
                    <i class="la la-trash" />
                    Delete
                </a>
            </div>
            <div class="kt-widget4__info ml-3">
                <span
                    class="kt-font-bold"
                >
                    {{ authMethod.twoFactorAddress }}
                </span>
                <p
                    v-if="authMethod.twoFactorValidated"
                    class="kt-widget4__text kt-font-success"
                >
                    Verified: {{ authMethod.twoFactorType }}
                </p>
                <p
                    v-else
                    class="kt-widget4__text kt-font-danger"
                >
                    This two factor method has not been verfied.
                    click the verify button to send a verification
                </p>
            </div>
            <!-- <a
                href="#"
                class="btn btn-sm btn-icon-sm"
                @click.stop.prevent="removeTeacher(teacher)"
            >
                <i class="la la-close" />
            </a> -->
        </div>
    </div>
</div>
</template>

<script lang="ts">

import { mapState, mapGetters } from 'vuex';
import Vue from 'vue';
import Types from '../store/Types';
import twoFactorMixins from '../store/mixins/twoFactorMixins';

export default Vue.extend({
    name: 'SetupTwoFactorAuth',
    mixins: [twoFactorMixins],
    props: {
        fullScreen: {
            type: Boolean,
            required: false,
            default: false,
        },
        incrementKey: {
            type: Function,
            required: false,
            default: () => {},
        },
    },
    data() {
        return {
            key: 0,
            saving: false,
        };
    },
    computed: {
        userTwoFactorAuthMethodId: {
            get() {
                return this.$store.state.user.twoFactorAuth.userTwoFactorAuthMethodId;
            },
            set(val) {
                this.$store.commit(Types.mutations.SET_USER_TWO_FACTOR_AUTH_METHOD_ID, val);
            },
        },
        ...mapState({
            user: (state) => state.user,
            deviceType: (state) => state.deviceType,
        }),
        ...mapGetters({
            twoFactorEnabled: Types.getters.IS_TWO_FACTOR_AUTH_ENABLED,
            twoFactorRequired: Types.getters.IS_TWO_FACTOR_AUTH_REQUIRED,
        }),
    },
    methods: {
        startChallengeForMethod(authMethod) {
            this.userTwoFactorAuthMethodId = authMethod.userTwoFactorAuthMethodId;
        },
        remove(authMethod) {
            const v = this;
            this.$store.dispatch(Types.actions.REMOVE_TWO_FACTOR_AUTH_METHOD, {
                userTwoFactorAuthMethodId: authMethod.userTwoFactorAuthMethodId,
                callback(err) {
                    if (err) return v.showError(err);
                    v.incrementKey();
                    v.key += 1;
                },
            });
        },
    },
});
</script>

<style scoped>
div.invalid-feedback {
    padding-top: 4px;
}
</style>
