var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.user
    ? _c("div", { key: _vm.key }, [
        _c(
          "div",
          { staticClass: "kt-widget4 p-3" },
          _vm._l(_vm.twoFactorMixinsAuthMethods, function (authMethod) {
            return _c(
              "div",
              {
                key: `${authMethod.twoFactorType}-${authMethod.twoFactorAddress}`,
                staticClass: "kt-widget4__item",
              },
              [
                !authMethod.twoFactorValidated
                  ? _c(
                      "div",
                      { staticClass: "kt-widget4__pic kt-widget4__pic--pic" },
                      [
                        _c(
                          "a",
                          {
                            staticClass:
                              "btn btn-sm btn-label-brand btn-bold text-nowrap",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.startChallengeForMethod(authMethod)
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "la la-check" }),
                            _vm._v(" Verify "),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                !_vm.fullScreen || !authMethod.twoFactorValidated
                  ? _c(
                      "div",
                      { staticClass: "kt-widget4__pic kt-widget4__pic--pic" },
                      [
                        _c(
                          "a",
                          {
                            staticClass:
                              "btn btn-sm btn-label-danger btn-bold text-nowrap",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.remove(authMethod)
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "la la-trash" }),
                            _vm._v(" Delete "),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _c("div", { staticClass: "kt-widget4__info ml-3" }, [
                  _c("span", { staticClass: "kt-font-bold" }, [
                    _vm._v(" " + _vm._s(authMethod.twoFactorAddress) + " "),
                  ]),
                  authMethod.twoFactorValidated
                    ? _c(
                        "p",
                        { staticClass: "kt-widget4__text kt-font-success" },
                        [
                          _vm._v(
                            " Verified: " +
                              _vm._s(authMethod.twoFactorType) +
                              " "
                          ),
                        ]
                      )
                    : _c(
                        "p",
                        { staticClass: "kt-widget4__text kt-font-danger" },
                        [
                          _vm._v(
                            " This two factor method has not been verfied. click the verify button to send a verification "
                          ),
                        ]
                      ),
                ]),
              ]
            )
          }),
          0
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }